const getClosest = (elem, selector) => {
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}
	return null;
};

const showEl = (el) => el.style.display = 'inherit';
const hideEl = (el) => el.style.display = 'none';

const addClass = (el, className) => el && el.classList.add(className);
const removeClass = (el, className) => el && el.classList.remove(className);

const fadeIn = (el, i) => {
    el.style.opacity = 0;
    el.style.display = "inherit" || "none";
  
    (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val += .1) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
};

const fadeOut = (el, i) => {
    el.style.opacity = 0;
    el.style.display = "inherit" || "none";
  
    (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val -= .1) < 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
};

export {
	getClosest,
	showEl,
	hideEl,	
	addClass,
	removeClass,
	fadeIn,
	fadeOut
}