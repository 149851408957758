import { fadeIn, fadeOut } from '../helpers';
// See https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const init = () => {
    const slideOut = document.getElementById('slide-out');   
    const btnClose = slideOut.querySelector('.btn-close');
    btnClose.addEventListener('click', (event) => handleClose(event, slideOut));              

    document.querySelector('.sidenav-overlay').addEventListener("click", (event) => {
        closeSlideOut(event, slideOut);
    });

    addBookingButtonListeners(slideOut);
}

const addBookingButtonListeners = (slideOut) => {
    // listen to all element with the data attribute
    document.addEventListener('click', e => {
        if (e.target.dataset.target !== 'slide-out') return;
              
        handleClick(e, slideOut);
    });
}

const handleClose = (event, slideOut) => {    
    hideSlideOut(slideOut);
    hideSlideOutOverlay();    
    enableBodyScroll(slideOut);    
}

const handleClick = async (event, slideOut) => {    
    const spinner = slideOut.querySelector('.spinner');

    showSlideOut(slideOut);
    showSlideOutOverlay();
    
    const spinnerTimeout = setTimeout( showSpinner, 100, spinner);
    await addBookingScript();
    
    DMN.val('stylesheet', 'website');
    
    disableBodyScroll(slideOut);

    slideOut.querySelector('.dmn-form button.submit').addEventListener('click', e => {
        console.log('e');
        handleClose(e, slideOut);
    });

    slideOut.querySelector('input[id="dmn-date"]').addEventListener('focus', e => {
        document.activeElement.blur();
    });
    
    clearTimeout(spinnerTimeout);
    //hideSpinner(spinner);
    if (spinner) spinner.remove();
}

let addBookingScript = () => new Promise((resolve, reject) => {
    const dmn = document.getElementById('dmn-js');
    const script = document.createElement("script");
        
    if (dmn.childNodes.length > 0) {
        resolve('booking iframe exists');

        return;
    }    

    script.addEventListener("load", function(event) {
        setTimeout( fadeIn, 500, dmn);

        resolve("Script finished loading and executing");
    });

    dmn.style.opacity = 0;
    const src = dmn.dataset.src;
    const venueId = (dmn.dataset.venueid ? dmn.dataset.venueid : '5daedebc0b10830b7512ff55');

    script.src = `${src}${venueId}`;
    
    script.async = true;
    
    dmn.appendChild(script);    
});

const showSlideOut = slideOut => slideOut.classList.add('active');
const hideSlideOut = slideOut => slideOut.classList.remove('active');

const showSlideOutOverlay = () => document.querySelector('.sidenav-overlay').classList.add('active');
const hideSlideOutOverlay = () => document.querySelector('.sidenav-overlay').classList.remove('active');

const showSpinner = spinner =>  spinner.classList.add('active');
const hideSpinner = spinner =>  {
    fadeOut(spinner);
    spinner.classList.remove('active')
};    

const closeSlideOut = (event, slideOut) => {
    // If user clicks inside the element, do nothing
    if (event.target.closest(".sidenav") || event.target.classList.contains('sidenav-trigger')) return;
    
    // If user clicks outside the element, hide it!
    handleClose(event, slideOut);
}

export {
    init,
    addBookingButtonListeners
}

init();   